import React from 'react';
import PropTypes from 'prop-types';

import './layout.css';
import Helmet from 'react-helmet';

const Layout = ({ children }) => {
  const mainRef = React.createRef();
  // useEffect(() => {

  //   document.body.addEventListener('mouseleave', (_) => {
  //     requestAnimationFrame(() => {
  //       mainRef.current && mainRef.current.classList.remove('hovering-off-content');
  //     });
  //   });

  //   mainRef.current.addEventListener('mousemove', it => {
  //     const isHoveringTriggerArea =
  //       it.srcElement === mainRef.current ||
  //       it.srcElement && it.srcElement.classList.contains('square');
  //     if (isHoveringTriggerArea) {
  //       mainRef.current && mainRef.current.classList.add('hovering-off-content');
  //       requestAnimationFrame(() => {
  //         // console.log(`x:${it.clientX} y:${it.clientY}`);
  //       });
  //     } else {
  //       requestAnimationFrame(() => {
  //         mainRef.current && mainRef.current.classList.remove('hovering-off-content');
  //       });
  //     }
  //   });
  // });
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <>
      {isProduction && (
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-X3GZRNV8F0"
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-X3GZRNV8F0');
        `}
          </script>
        </Helmet>
      )}
      <div
        style={{
          margin: `0 auto`,
          padding: `0`,
        }}
      >
        <main ref={mainRef}>{children}</main>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
